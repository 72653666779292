import axios from 'axios'

// constants
const initData = {
  array : [],
  element: {}
}

const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS'
const GET_PROJECT_SUCCESS = 'GET_PROJECT_SUCCESS'

// reducer
export default function projectsReducer(state = initData, action) {
  switch (action.type) {
    case GET_PROJECTS_SUCCESS:
      return { ...state, array: action.payload }
    case GET_PROJECT_SUCCESS:
      return { ...state, element: action.payload }
      default:
        return state
  }
}

// actions 
export const getProjectsAction = () => async (dispatch, getState) => {
  try{
    const res = await axios.get('https://bravosolucionesenmadera.com.ar/admin/wp-json/wp/v2/trabajos')
    dispatch({
      type: GET_PROJECTS_SUCCESS,
      payload: res.data
    })
  } catch (error){
    console.log(error)
  }
}

export const getProjectAction = (w) => async (dispatch, getState) => {
  const work = w;
  const allProjects = getState().projects.array;
  const single = allProjects.filter(p => p.id === work);
  dispatch({
    type: GET_PROJECT_SUCCESS,
    payload: single[0]
  })
  
}