import { createStore, combineReducers, compose, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'

import projectsReducer from './projectsDucks'

const rootReducer = combineReducers({
  projects: projectsReducer
})

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default function generateStore(){
  const store = createStore( rootReducer, composeEnhancers ( applyMiddleware(thunk) ) )
  return store;
}