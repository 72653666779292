import React, { useEffect } from 'react'

function Navbar() {

  const openMenu = () => {
    document.querySelector('.burger').classList.toggle('nav-open')
    document.querySelector('.navbar-collapse').classList.toggle('show')
  }
  const handleScroll = (element) => {
    let topElem = document.getElementById(element)
    let top = topElem.offsetTop
    if (element === 'la-empresa') {
      window.scrollTo({ top: top - 160, behavior: 'smooth' })
    } else {
      window.scrollTo({ top: top - 25, behavior: 'smooth' })
    }
    openMenu()
  }

  useEffect(() => {
    window.onscroll = function() {
      if(window.pageYOffset > 50) {
        document.querySelector('.main-header').classList.add('header-background');
      } else {
        document.querySelector('.main-header').classList.remove('header-background');
      }
    };
  }, [])

  return (
    <>
      <header className='main-header'>
        <div className='container medium'>
          <div className='row'>
            <div className='col-12'>
              <nav className="navbar navbar-expand-lg">
                <a className="navbar-brand c-pointer" onClick={() => handleScroll('inicio')}>
                  <img src='images/bravo-logo.svg' className='img-fluid' alt='Bravo - SOLUCIONES EN MADERA' title='Bravo - SOLUCIONES EN MADERA'   />
                </a>
                <div className='botton-menu' onClick={() => openMenu()} >
                    <div className='box-shadow burger'>
                      <div className='nav-button'>
                        <span id='nav-icon3'>
                          <span></span>
                          <span></span>
                          <span></span>
                          <span></span>
                        </span>
                      </div>
                    </div>
                  </div>
                <div className='collapse navbar-collapse' id='navbarSupportedContent'>
                  <ul className="navbar-nav ml-auto">
                    <li className="nav-item active">
                      <a className="nav-link" onClick={() => handleScroll('inicio')}>Inicio</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" onClick={() => handleScroll('la-empresa')}>La empresa</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" onClick={() => handleScroll('productos-y-servicios')}>Productos Y Servicios</a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link" onClick={() => handleScroll('contacto')}>Contacto</a>
                    </li>
                  </ul>
                  <ul className='socials justify-content-start justify-content-lg-end mt-3 mt-lg-0'>
                    <li>
                      <a href="https://www.facebook.com/Bravosolucionesenmadera" target='_blank' rel='noopener noreferrer'><span><i arie-hidden='true' className='fab fa-facebook-f'></i></span></a>
                    </li>
                    <li>
                      <a href="https://www.instagram.com/bravosolucionesenmadera/" target='_blank' rel='noopener noreferrer'><span><i arie-hidden='true' className='fab fa-instagram'></i></span></a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Navbar;