import React from "react";
import Navbar from "../layout/Navbar";
import Carousel from "../shared/Carousel";
import Form from "../shared/Form";
import ProjectList from "../shared/ProjectList";
import { Provider } from "react-redux";
import generateStore from "../../redux/store";

function Home() {
  const store = generateStore();

  const handleScroll = (element) => {
    let topElem = document.getElementById(element);
    let top = topElem.offsetTop;
    if (element === "la-empresa") {
      window.scrollTo({ top: top - 160, behavior: "smooth" });
    } else {
      window.scrollTo({ top: top - 25, behavior: "smooth" });
    }
  };

  return (
    <>
      <Navbar /> {/* NavBar  */}
      <section className="hero" id="inicio">
        {" "}
        {/* Hero  */}
        <Carousel home={true} />
      </section>
      <section className="la-empresa" id="la-empresa">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <article className="text-empresa">
                <h2 className="title f-t-light">La empresa</h2>
                <p>
                  Todo empieza y termina con la madera. De esta pasión nació
                  Bravo, fundada en 1959 por Tomas Juan Bravo, el gran pionero
                  de este presente de crecimiento. Hoy, a más de 60 años, sus
                  hijos y nietos formamos parte de aquel gran sueño que día a
                  día forjamos con fuerza y convicción.
                </p>
                <p>
                  <strong>El uso de tecnologías del más alto nivel</strong>,
                  combinadas con una mano de obra <strong>muy artesanal</strong>
                  , hacen que{" "}
                  <strong>el producto se eleve a su máxima expresión</strong>,
                  es decir, se unen la tecnología y el buen uso de los métodos
                  tradicionales.
                </p>
              </article>
            </div>
            <div className="col-lg-6">
              <article>
                <div className="card-empresa">
                  <h3 className="title">
                    Somos una empresa familiar que trasciende generaciones{" "}
                    <strong>brindando confinaza y calidad</strong>.
                  </h3>
                  <img
                    className="img-fluid"
                    src="images/bravo-somos-una-empresa-familiar-que-trasciende-generaciones-brindando-confianza-y-calidad.jpg"
                    alt="Somos una empresa familiar que trasciende generaciones brindando confinaza y calidad"
                    title="Somos una empresa familiar"
                  />
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section className="productos-y-servicios" id="productos-y-servicios">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 mx-auto text-center px-lg-0">
              <h3 className="title f-t-light underline">
                Productos y servicios
              </h3>
              <p className="f-g-book">
                Nuestros productos, año a año van mutando junto a los gustos y
                tendencias de casi tres generaciones de arquitectos y
                consumidores. Esta búsqueda se traduce en{" "}
                <span className="f-g-bold">la innovación</span> como filosofía
                de producción de Bravo
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="container content-works">
          <div className="row">
            <div className="col-lg-10 mx-lg-auto handle-scroll">
              <Provider store={store}>
                <ProjectList />
              </Provider>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-10 mx-lg-auto text-center underline">
              <p className="f-g-light">
                Para ponerte en contacto y cotizar tu proyecto hacé{" "}
                <span
                  className="f-g-book c-pointer"
                  onClick={() => handleScroll("contacto")}
                >
                  clic aquí
                </span>
                .
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        className="featured-phrase"
        style={{
          backgroundImage: `url(images/bravo-bg-propuesta-de-valor.jpg)`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 mx-lg-auto text-center">
              <h4 className="f-g-light">
                Contamos con diseñadores con amplia experiencia y creatividad
                capaces de realizar cualquier tipo de proyecto, <br />{" "}
                <span className="f-g-bold">
                  el único límite es su imaginación
                </span>
                .
              </h4>
            </div>
          </div>
        </div>
      </section>
      <section className="contacto" id="contacto">
        <div className="container">
          <div className="row">
            <div className="col-10 mx-auto mx-lg-0 col-lg-6 mb-5 mb-lg-0">
              <h3 className="title f-t-light pt-lg-5">Contacto</h3>
              <p className="f-g-book">
                <a href="mailto:bsolucionesenmadera@gmail.com">
                  bsolucionesenmadera@gmail.com
                </a>
              </p>
              <p className="f-g-book">
                <a href="https://goo.gl/maps/SbjUKBLH1aNqjjRo8">
                  Carlos Hardy 775 - Rcia. - Chaco
                </a>
              </p>
              <p className="f-g-book">
                <a href="tel:+549362-4645646">362-4645646</a>
              </p>
              <p>
                <a
                  href="https://www.facebook.com/Bravosolucionesenmadera"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span>
                    <i arie-hidden="true" className="fab fa-facebook-f"></i>
                  </span>
                </a>
                <a
                  href="https://www.instagram.com/bravosolucionesenmadera/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ml-1"
                >
                  <span>
                    <i arie-hidden="true" className="fab fa-instagram"></i>
                  </span>
                </a>
                <span className="f-g-bold ml-2">bravosolucionesenmadera</span>
              </p>
            </div>
            <div className="col-lg-6">
              <div className="content-form">
                <Form />
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer
        style={{
          backgroundImage: `url(images/bravo-carlos-hardy-775-resistencia-chaco.jpg)`,
        }}
      >
        {" "}
      </footer>
    </>
  );
}

export default Home;
