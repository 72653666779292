import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";

function Carousel( { home, popup, gallery }) {

  if(home){
    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      autoplay: false
    };
  
  
    return(
      <>
        <Slider {...settings}>
          <div className='item-carousel'>
            <img src='images/bravo-transformando-tus-espacios-desde-1959.jpg' className='item-img' alt='Transformando tus espacios desde 1959' title='Transformando tus espacios desde 1959'  />
            <div className='slide-info' style={{ backgroundImage: `url(images/bravo-bg-recurso-de-marca.png)` }}>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='heading-title'>
                      <h1 className='f-t-light'>Transformando <br /> tus espacios <br /><strong>desde 1959</strong></h1>
                    </div>
                  </div>
                </div>
                <div className='row mt-5 mt-lg-0'>    
                  <div className='col-2 d-flex'>
                    <div className='cta mt-auto'>
                      <a className='btn btn-primary'>
                        <img src='images/bravo-arrow-down.svg' className='img-fluid'  />
                      </a>
                    </div>
                  </div>
                  <div className='col-10'>
                    <div className='heading-description'>
                      <ul>
                        <li>CALIDAD</li>
                        <li>EXPERIENCIA</li>
                        <li>PROFESIONALISMO</li>
                        <li>TECNOLOGÍA</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='item-carousel'>
            <img src='images/bravo-detalles-que-marcan-diferencia.jpg' className='item-img' alt='Detalles que marcan la diferencia' title='Detalles que marcan la diferencia'  />
            <div className='slide-info' style={{ backgroundImage: `url(images/bravo-bg-recurso-de-marca.png)` }}>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='heading-title'>
                      <h1 className='f-t-light'>Detalles <br /><strong>que marcan <br />la diferencia</strong></h1>
                    </div>
                  </div>
                </div>
                <div className='row mt-5 mt-lg-0'>    
                  <div className='col-2 d-flex'>
                    <div className='cta mt-auto'>
                      <a className='btn btn-primary'>
                        <img src='images/bravo-arrow-down.svg' className='img-fluid'  />
                      </a>
                    </div>
                  </div>
                  <div className='col-10'>
                    <div className='heading-description'>
                      <ul>
                        <li>CALIDAD</li>
                        <li>EXPERIENCIA</li>
                        <li>PROFESIONALISMO</li>
                        <li>TECNOLOGÍA</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='item-carousel'>
            <img src='images/bravo-tus-proyectos-en-buenas-manos.jpg' className='item-img' alt='Detalles que marcan la diferencia' title='Detalles que marcan la diferencia'  />
            <div className='slide-info' style={{ backgroundImage: `url(images/bravo-bg-recurso-de-marca.png)` }}>
              <div className='container'>
                <div className='row'>
                  <div className='col-12'>
                    <div className='heading-title'>
                      <h1 className='f-t-light'>Tus proyectos <br /><strong>en buenas manos</strong></h1>
                    </div>
                  </div>
                </div>
                <div className='row mt-5 mt-lg-0'>    
                  <div className='col-2 d-flex'>
                    <div className='cta mt-auto'>
                      <a className='btn btn-primary'>
                        <img src='images/bravo-arrow-down.svg' className='img-fluid'  />
                      </a>
                    </div>
                  </div>
                  <div className='col-10'>
                    <div className='heading-description'>
                      <ul>
                        <li>CALIDAD</li>
                        <li>EXPERIENCIA</li>
                        <li>PROFESIONALISMO</li>
                        <li>TECNOLOGÍA</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </>
    )
  } else if(popup){

    var settings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: true,
      autoplay: true,
      adaptiveHeight: false
    };

    return(
      <>
        <Slider {...settings}>
          {
            gallery.length > 0 &&
            gallery.map(img => (
              <div className='carousel-item' key={img.ID}>
                <img src={img.sizes.medium_large} className='img-fluid' alt='' />
              </div>
            ))
          }
        </Slider>
      </>
    )

  }

}

export default Carousel;