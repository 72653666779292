import React, { useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getProjectsAction, getProjectAction } from '../../redux/projectsDucks'
import Carousel from '../shared/Carousel'

const ProjectList = () => {
  const dispatch = useDispatch()

  const projects = useSelector(store => store.projects.array)
  const project = useSelector(store => store.projects.element)

  const handleScroll = (element) => {
    let topElem = document.getElementById(element)
    let top = topElem.offsetTop
    window.scrollTo({ top: top - 25, behavior: 'smooth' })
  }

  const activePopup = (w) => {
    if(w){
      dispatch(getProjectAction(w))
    }
    document.querySelector('.popup').classList.toggle('active')
    document.querySelector('body').classList.toggle('hidden')
  }

  const solicitarPresupuesto = () =>{
    activePopup()
    handleScroll('contacto')
  }

  const selectedWork = (id) => {
    dispatch(getProjectAction(id))
  }

  useEffect(() => {
    dispatch(getProjectsAction())
  }, []);
  
  return (
    <>
      <div className='row works'>
        {
          projects.map(item => (
            <div className='col-2 col-lg-4 work' key={item.id}>
              <article className='' style={{ backgroundImage: `url(${item.acf.cover.url})` }}>
                <div className='work--info text-center underline'>
                  <h3 className='f-t'>{item.title.rendered}</h3>
                  <button className='btn btn-secundary f-t' onClick={() => activePopup(item.id)}>Ver trabajos</button>
                </div>
              </article>
            </div>
            ))
          }
      </div>
      {/* Popup */}
      <div className='popup'>
          <div className='close' onClick={() => activePopup()}>
            x
          </div>
          {
            Object.keys(project).length > 0 &&
            <div className='container'>
              <div className='row justify-content-center pt-5 pt-lg-0'>
                <div className='col-12 col-lg-2 my-lg-auto'>
                  <ul className='categories'>
                    {
                      projects.map(item => (
                        <li key={item.id} className={item.id === project.id ? 'cat active' : 'cat'} onClick={() => selectedWork(item.id)}>{item.title.rendered}</li>
                      ))
                    }
                  </ul>
                </div>
                <div className='col-12 col-lg-6'>
                  <div className='content-gallery'>
                    <Carousel popup={true} gallery={project.acf.gallery} />
                  </div>
                </div>
                <div className='col-12 col-lg-4 my-lg-auto'>
                  <div className='algo'>
                    <div className='algo--header'>
                      <h2 className='title'>{project.title.rendered}</h2>
                    </div>
                    <div className='algo--body f-g-book'>
                      <div dangerouslySetInnerHTML={{__html: project.acf.description}} />
                    </div>
                    <div className='algo--footer'>
                      <button className='btn btn-work f-g-book' onClick={() => solicitarPresupuesto()}>SOLICITAR PRESUPUESTO</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
    </>
  )
}


export default ProjectList;