import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import axios from 'axios'

function Form (){

  const [sending, setSending] = useState(false)
  const { register, handleSubmit, formState: { errors } } = useForm();

  const onSubmit = (data) => {
    setSending(true)
    const form = new FormData()
    form.append('name', data.name)
    form.append('email', data.email)
    form.append('phone', data.phone)
    form.append('message', data.message)

    axios
      .post('http://bravosolucionesenmadera.tk/msg/message.php', form)
      .then((res) => {
        const message = res.data
        setSending(false)
        if (message === 'success') {
          document.getElementById("form-contact").reset()
          document.getElementById('alert-seccess').classList.toggle('show')
        } else {
          document.getElementById('alert-danger').classList.toggle('show')
        }
      })
      .catch((error) => {
        console.log('catch ', error)
        document.getElementById('alert-danger').classList.toggle('show')
      })
  }


  return(
    <> 
      <form
        onSubmit={handleSubmit(onSubmit)}
        id='form-contact'
      >
        <div class="alert alert-success" id='alert-seccess' role="alert">
          Tu mensaje se envío.
        </div>
        <div class="alert alert-danger" id='alert-danger' role="alert">
          Algo salió mal. Intenta de nuevo por favor.
        </div>
        <div className="form-group">
          <input
            {...register('name', { required: true })}
            type='text'
            placeholder='Nombre'
            className={errors.name ? 'form-control is-invalid' : 'form-control'}
          />
          {errors.name && (
            <div className='invalid-feedback'>Tenes que ingresar un Nombre.</div>
          )}
        </div>
        <div className="form-group">
          <input
            {...register('email', { required: true }, { pattern: {value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i }})}
            type='email'
            placeholder='E-mail'
            className={
              errors.email ? 'form-control is-invalid' : 'form-control'
            }
          />
          {errors.email && (
            <div className='invalid-feedback'>
              Tenes que ingresar un E-mial válido.
            </div>
          )}
        </div>
        <div className="form-group">
          <input
            {...register('phone', { required: true })}
            type='text'
            placeholder='Teléfono'
            className={
              errors.phone ? 'form-control is-invalid' : 'form-control'
            }
          />
          {errors.phone && (
            <div className='invalid-feedback'>
              Tenes que ingresar un número de Teléfono.
            </div>
          )}
        </div>
        <div className="form-group">
          <textarea
            className="form-control"
            placeholder='Mensaje'
            rows="4" {...register('message', { required: true })}
          ></textarea>
        </div>
        <div className='form-group mb-3'>
          <button type='submit' className='btn btn-form f-g-light'>
            {sending
              ?
              <>
                <div className='spinner-border text-light' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
                <span>Enviando</span>
              </>
              :
              <span>Enviar</span>
            }
          </button>
        </div>
      </form>
    </>
  )
}

export default Form;